<template>
  <div>
    <DoctorCard
      :doctor-code="search.DoctorCode"
      :doctor-name="doctorName"
      :dept-name="deptName"
    />
    <div v-if="list.length > 0" class="scheduleList">
      <div class="title">医生排班情况</div>
      <div class="list">
        <ul>
          <van-row>
            <li v-for="(item, index) in list" :key="index">
              <van-col :span="1">
                <van-image
                  width="0.426rem"
                  :src="require('@/assets/dept_images/clock.png')"
                />
              </van-col>
              <van-col :span="8">&nbsp;{{ item.date }}</van-col>
              <van-col :span="3">
                {{ item.dateTypeName }}
              </van-col>
              <van-col :span="3">
                <van-tag v-if="item.isClose == 1" type="danger">停诊</van-tag>
              </van-col>
              <van-col :span="4">
                <van-tag v-if="item.count <= 0" type="warning ">号已满</van-tag>
              </van-col>
              <div
                :class="[item.count > 0 ? 'number ' : 'number gray']"
                @click="toInfo(item)"
              >
                {{ item.count >= 100 ? '余99+' : '余' + item.count }}
              </div>
            </li>
          </van-row>
        </ul>
      </div>
    </div>
    <van-empty v-else description="暂无医生排班，请重新查询" />
  </div>
</template>

<script>
  import DoctorCard from '@/components/DoctorCard'
  import { Toast } from 'vant'
  import { getScheduleDetailList } from '@/api/his/his'
  export default {
    name: 'Index',
    components: { DoctorCard },
    data() {
      return {
        list: [],
        search: {
          DeptCode: '',
          DoctorCode: '',
          BeginTime: '',
          EndTime: '',
        },
        doctorName: '',
        deptName: '',
        fee: '',
      }
    },
    created() {
      let query = this.$route.query
      this.search.DeptCode = this.$Base64.decode(query.deptCode)
      this.search.DoctorCode = this.$Base64.decode(query.doctorCode)
      this.doctorName = this.$Base64.decode(query.doctorName)
      this.deptName = this.$Base64.decode(query.deptName)
      this.search.BeginTime = this.$Base64.decode(query.beginTime)
      this.search.EndTime = this.$Base64.decode(query.endTime)
      this.fee = this.$Base64.decode(query.fee)
      console.log(query)
      this.fetchData()
    },
    methods: {
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getScheduleDetailList(this.search)
        this.list = data.scoureList
        Toast.clear()
      },

      toInfo(item) {
        console.log(item)
        if (item.isClose == 1) {
          Toast('已停诊')
          return
        }

        if (item.count <= 0) {
          Toast('号已满')
          return
        }

        this.$router.push({
          path: '/scheduleNumber',
          query: {
            deptCode: this.$Base64.encode(item.deptCode),
            doctorCode: this.$Base64.encode(this.search.DoctorCode),
            deptName: this.$Base64.encode(item.deptName),
            doctorName: this.$Base64.encode(this.doctorName),
            date: this.$Base64.encode(item.date),
            dateType: this.$Base64.encode(item.dateType),
            fee:
              this.fee === ''
                ? this.$Base64.encode(item.fee)
                : this.$Base64.encode(this.fee),
          },
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .scheduleList {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    .title {
      font-weight: bolder;
      padding: 0.426rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
    }
    .title::before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 1em;
      border-radius: 2px;
      margin-right: 0.5em;
      background: #1691fe;
      overflow: hidden;
    }
    .list {
      padding: 15px;
      li {
        padding: 0.3rem 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        .number {
          background: #1691fe;
          width: fit-content;
          padding: 0 15px;
          color: #fff;
          line-height: 30px;
          border-radius: 15px;
          float: right;
          white-space: nowrap;
        }
        .gray {
          background: #bebebe;
        }
      }
    }
  }
</style>
